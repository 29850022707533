// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

//import { createApp } from 'vue'
//import App from '../app.vue'
//
//document.addEventListener('DOMContentLoaded', () => {
//  createApp(App).mount('#app')
//})

import $ from 'jquery/dist/jquery.js';
import Rails from '@rails/ujs';

import 'virtual:windi.css'
import '~/stylesheets/application.css'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dom } from "@fortawesome/fontawesome-svg-core";
library.add(fas);
dom.watch();

import copy from "copy-to-clipboard"

// ujs
// FIXME: productionだとエラーになる / developmentだとないと動かない
try {
  Rails.start()
} catch (error) {
  console.log(error)
}

document.addEventListener('DOMContentLoaded', function() {
    // open
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
        for (var i = 0; i < burger.length; i++) {
            burger[i].addEventListener('click', function() {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
        for (var i = 0; i < close.length; i++) {
            close[i].addEventListener('click', function() {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    if (backdrop.length) {
        for (var i = 0; i < backdrop.length; i++) {
            backdrop[i].addEventListener('click', function() {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

  // copy button
  const copyButtons = document.querySelectorAll("span.copy-button");
  console.log(copyButtons)
  copyButtons.forEach(element => element.addEventListener("click", (event) => {
    const text = event.target.closest("p").querySelector(".copy-target").innerText
    copy(text)

    const message = event.target.closest("p").querySelector(".copy-message")
    message.classList.remove("hidden")

    event.stopPropagation();
  }));
});
